/* .searchbar {
  width: 50vw;
} */

.searchBar input {
  border-radius: 0.5em;
  border-style: solid;
  border-color: grey;
  border-width: 0.25px;
  background-color: #eeeeee;
  outline: none;
  width: 70vw;
  /* min-width: 100%; */
  transition: box-shadow 500ms;
}

@media screen and (max-width: 599px) {
  .hasResults .searchBar input {
    width: 60vw;
  }
  .hasResults {
    margin-right: 15%;
  }
}

.searchBar input:hover,
.searchBar input:active,
.searchBar input:focus {
  box-shadow: inset rgba(100, 100, 111, 0.2) 0px 0px 10px 0px;
}

.searchBar span {
  font-size: 1.5em;
  margin-right: 0.5em;
}

.dark .searchBar input {
  border-color: grey;
  background-color: #13243b;
}

.dark .searchBar input:hover,
.dark .searchBar input:active,
.dark .searchBar input:focus {
  /* box-shadow: inset rgba(100, 100, 111, 0.2) 0px 0px 10px 0px; */
  box-shadow: inset rgba(156, 156, 174, 0.5) 0px 0px 10px 0px;
}

/* label > span {
  margin: 0em 0em 0em 0em;
  padding: 0em 0.5em 0em 0em;
}

label > input {
  margin: 0.25em;
  border-radius: 0.5em;
  background-color: #eeeeee;
  border-style: none;
  height: 1em;
  padding: 0.6em;
  outline: none;
  text-align: left;
  box-shadow: none;
  transition: box-shadow 500ms;
}

label > input:hover {
  border-radius: 0.5em;
  box-shadow: inset rgba(100, 100, 111, 0.2) 0px 0px 10px 0px;
  box-shadow: rgba(100, 100, 111, 0.3) 0px 7px 29px 0px;
}

label > input:focus {
  box-shadow: inset rgba(100, 100, 111, 0.2) 0px 0px 20px 0px;
}

.searchBar {
  margin: 1em;
  min-width: 100vw;
  min-height: 100vh;
  text-align: left;
  transition: 500ms;
  margin-top: 2rem;
}

.dark label > input:hover {
  box-shadow: inset rgba(156, 156, 174, 0.2) 0px 0px 10px 0px;
}

.dark label > input:focus {
  box-shadow: inset rgba(200, 200, 221, 0.2) 0px 0px 20px 0px;
}

.dark label > input {
  background-color: #13243b;
} */
